import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Layout, LatestNews, Article, SeoComponent, ArticleSharePage, SponsorCarousel } from 'components';
import { LanguageContext } from 'context';

const NewsTemplate = ({ data, pageContext }) => {
  const article = data.contentfulArticle;
  const headerItems = data.contentfulWebHeaderNavbar;
  const sponsors = data.contentfulWebSponsorCarousel;
  const menuItems = data.allContentfulWebSite.nodes
    .flatMap((node) => node.pages.flatMap((p) => [p, p.subPages]))
    .flat()
    .filter(Boolean)
    .filter((item) => item.menuRelated);
  const topMenuItems = data.allContentfulWebSite.nodes
    .flatMap((node) => node.pages.flatMap((p) => [p, p.subPages]))
    .flat()
    .filter(Boolean)
    .filter((item) => item.topMenuRelated);
  const staticData = data.allContentfulDictionary.nodes;
  const articles = data.contentfulWebLatestNewsComponent;

  useEffect(() => {
    if (pageContext.nodeLocale === 'ar-SA') {
      document.documentElement.setAttribute('dir', 'rtl');
      document.documentElement.setAttribute('lang', 'ar');
      document.body.classList.add('rtl');
    } else {
      document.documentElement.removeAttribute('dir');
      document.documentElement.removeAttribute('lang');
      document.body.classList.remove('rtl');
    }
  }, [pageContext.nodeLocale]);

  return (
    <LanguageContext.Provider
      value={{
        currentLang: pageContext.nodeLocale,
        staticData,
      }}
    >
      <SeoComponent page={article} />
      <Layout pageContext={pageContext} menuItems={menuItems} headerItems={headerItems} topMenuItems={topMenuItems}>
        <Article article={article} />
        <ArticleSharePage articles={articles} pageContext={pageContext} />
        <LatestNews component={articles} />
        <SponsorCarousel component={sponsors} />
      </Layout>
    </LanguageContext.Provider>
  );
};

export const query = graphql`
  query GetNews($news: String, $nodeLocale: String, $seasonName: String) {
    allContentfulDictionary(filter: { node_locale: { eq: $nodeLocale } }) {
      nodes {
        contentItems {
          dictionary {
            title
          }
          keyString
          stringValue {
            stringValue
          }
        }
      }
    }
    allContentfulWebSite(
      filter: { webSlug: { eq: $seasonName }, node_locale: { eq: $nodeLocale } }
      sort: { order: ASC, fields: createdAt }
    ) {
      nodes {
        title
        webSlug
        showAlert
        pages {
          node_locale
          slug
          title
          id
          menuRelated
          topMenuRelated
          gtAction
          gtEvent
          gtEventCategory
          gtLabel
          subPages {
            ... on ContentfulWebPage {
              id
              slug
              title
              gtAction
              gtEvent
              gtEventCategory
              gtLabel
            }
          }
        }
      }
    }
    contentfulWebLatestNewsComponent(node_locale: { eq: $nodeLocale }) {
      title
      type
      articleCategory {
        slug
        article {
          id
          slug
          articleContent {
            articleContent
          }
          title
          shortDescription
          articleImage {
            gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
            description
          }
          articleDate(formatString: "DD MMMM, Y")
          featured
        }
      }
      buttonTab {
        label
        link
        gtLabel
        gtEvent
        gtAction
        gtEventCategory
      }
    }
    contentfulWebMailChimpForm(node_locale: { eq: $nodeLocale }) {
      title
      type
      mainTitle
      description {
        description
      }
      successfulRegistration {
        successfulRegistration
      }
      postUrl
    }
    contentfulWebGallery(node_locale: { eq: $nodeLocale }) {
      title
      type
      images {
        id
        caption
        image {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 85)
          description
        }
      }
      mainTitle
    }
    contentfulArticle(slug: { eq: $news }, node_locale: { eq: $nodeLocale }) {
      articleContent {
        articleContent
      }
      title
      slug
      shortDescription
      articleImage {
        gatsbyImageData(placeholder: BLURRED, quality: 90, layout: CONSTRAINED)
        description
      }
      articleDate(formatString: "DD MMMM, Y")
      node_locale
      categories {
        slug
      }
    }
    contentfulWebHeaderNavbar(node_locale: { eq: $nodeLocale }) {
      startDateCompetition
      days
      hours
      minutes
      seconds
      raceDate
      language
      newsItem
      logoImage {
        description
        file {
          url
        }
      }
      coloredLogoImage {
        description
        file {
          url
        }
      }
    }
    contentfulWebSponsorCarousel(node_locale: { eq: $nodeLocale }) {
      id
      title
      type
      sponsorsItems {
        link
        logoImage {
          gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
          description
        }
      }
    }
  }
`;

export default NewsTemplate;
